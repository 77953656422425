import { useEffect, useState } from 'react'
import './Header.styles.scss'
import { useLocation, useNavigate } from 'react-router-dom'

function Header() {
  const location = useLocation()
  const navigate = useNavigate()

  const [time, setTime] = useState('')

  useEffect(() => {
    const interval = setInterval(() => startClock(), 1000)

    return () => clearInterval(interval)
  }, [])

  const startClock = (timeStamp) => {
    const d = new Date()
    const offset = Math.round(d.getTimezoneOffset() / 60)
    setTime(
      `${padDigits(d.getUTCHours() - offset, 2)}:${padDigits(d.getUTCMinutes(), 2)}:${padDigits(
        d.getUTCSeconds(),
        2
      )}`
    )

    // window.requestAnimationFrame(startClock.bind(this))

    function padDigits(number, digits) {
      return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    }
  }

  return (
    <header>
      <div className={`time ${location.pathname === '/' ? 'active' : ''}`}>{time}</div>

      <nav>
        <button
          type="button"
          className={`text ${location.pathname === '/' ? 'active' : ''}`}
          onClick={() => {
            navigate('/about')
          }}
        >
          About
          <div className="line"></div>
        </button>
        <button
          type="button"
          className={`close ${location.pathname === '/' ? '' : 'active'}`}
          onClick={() => {
            navigate('/')
          }}
        >
          <div className="line linetop"></div>
          <div className="line linebottom"></div>
        </button>{' '}
      </nav>
    </header>
  )
}

export default Header
