import { useLocation } from 'react-router-dom'
import './Footer.styles.scss'
import { ReactComponent as Logo } from '../../assets/imgs/graphicks_logo.svg'
import { GraphicksCopy } from '../../data'
import { ReactComponent as WatsAppLogo } from '../../assets/imgs/watsapp.svg'

function Footer() {
  const { footer } = GraphicksCopy

  const location = useLocation()

  return (
    <footer className={`${location.pathname === '/about' ? 'about' : ''}`}>
      <div className="graphicks-logo">
        <Logo />
      </div>
      <a
        className="g-button watsapp"
        target="_blank"
        aria-label="Chat on WhatsApp"
        href={footer.url}
        rel="noreferrer"
      >
        <span>{footer.watsapp}</span>
        <WatsAppLogo />
      </a>
    </footer>
  )
}

export default Footer
