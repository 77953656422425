import reel from '../../assets/video/Graphicks_Sizzle_Reel_v3_compressed.mp4'
import Poster from '../../assets/imgs/poster.jpg'
import './Homepage.styles.scss'

function HomePage() {
  return (
    <div className="home-container">
      <div className="frame">
        <video className="myVideo" autoPlay muted loop playsInline>
          <source src={reel} type="video/mp4" />
        </video>

        <div className="overlay"></div>
      </div>

      <div className="reel">
        <video className="myVideo" autoPlay muted loop playsInline>
          <source src={reel} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default HomePage
