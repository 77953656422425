export const GraphicksCopy = {
  header: {},
  about: {
    intro:
      'We’re a creative agency that’s all about property. Whether it’s offices, retail, residential, or investment, we bring brands to life and make spaces stand out across London and the UK.',
    intro2: 'Effective property marketing built on experience, insight and creativity',
    play_button: 'Play showreel',
    showreel_url:
      'https://player.vimeo.com/video/1055260758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    contact_title: 'Get in touch.',
    contact_intro: 'To discuss a project:',
    telephone: '+44 (0)7525 131633',
    email: 'will@graphicks.co.uk',
    findus_title: 'Find us.',
    address1: 'Studio 5',
    address2: '6-8 Cole Street',
    address3: 'London SE1 4YH',
    map_link: 'https://maps.app.goo.gl/dGwxfZhqBGgUUnqF7',
    instagram: 'https://www.instagram.com/_graphicks/',
    linkedIn: 'https://www.linkedin.com/company/graphicks/'
  },
  footer: {
    watsapp: 'Chat with us',
    url: 'https://wa.me/+447525131633'
  }
}
