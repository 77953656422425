import { useLocation, useNavigate } from 'react-router-dom'
import { GraphicksCopy } from '../../data'
import { ReactComponent as InstaLogo } from '../../assets/imgs/insta_icon.svg'
import { ReactComponent as LinkedinLogo } from '../../assets/imgs/linkedin_icon.svg'
import './AboutPage.styles.scss'

function AboutPage() {
  const { about } = GraphicksCopy

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className={`about-container ${location.pathname === '/about' ? 'active' : ''}`}>
      <div className="panel">
        <div className="top">
          <div className="block">
            <p>{about.intro}</p>
          </div>
          <div className="block">
            <p className="p-strong">{about.intro2}</p>
          </div>
          <div className="block">
            <button
              type="button"
              className="g-button"
              onClick={() => {
                navigate('/showreel')
              }}
            >
              {about.play_button}
            </button>
          </div>
        </div>
        <div className="bottom">
          <div className="block">
            <h2 className="contact-title">{about.contact_title}</h2>
            <div className="details">
              <div>{about.contact_intro}</div>
              <div>
                <a href={`tel:${about.telephone}`}>{about.telephone}</a>
              </div>
              <div>
                <a href={`mailto:${about.email}`}>{about.email}</a>
              </div>
            </div>
          </div>
          <div className="block">
            <h3 className="contact-title">{about.findus_title}</h3>
            <a href={about.map_link} rel="noreferrer" target="_blank">
              <div>{about.address1}</div>
              <div>{about.address2}</div>
              <div>{about.address3}</div>
            </a>
          </div>
          <div className="block">
            <div className="socials">
              <a href={about.instagram} rel="noreferrer" target="_blank">
                <InstaLogo />
              </a>
              <a href={about.linkedIn} rel="noreferrer" target="_blank">
                <LinkedinLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
