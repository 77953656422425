import HomePage from './components/HomePage/HomePage.component'
import AboutPage from './components/AboutPage/AboutPage.component'
import ReelPage from './components/ReelPage/ReelPage.component'
import Header from './components/Header/Header.component'
import Footer from './components/Footer/Footer.component'

import './App.css'

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main">
        <HomePage />
        <AboutPage />
        <ReelPage />
      </div>
      <Footer />
    </div>
  )
}

export default App
