import { useLocation } from 'react-router-dom'
import { GraphicksCopy } from '../../data'
import './ReelPage.styles.scss'

function ReelPage() {
  const { about } = GraphicksCopy

  const location = useLocation()

  return (
    <div className={`showreel-container ${location.pathname === '/showreel' ? 'active' : ''}`}>
      <div className="panel">
        <iframe
          src={` ${location.pathname === '/showreel' ? about.showreel_url : ''}`}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          title="Graphicks_Showreel"
        ></iframe>
      </div>
    </div>
  )
}

export default ReelPage
